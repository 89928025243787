// @ts-nocheck

import { wrapIn, setBlockType } from 'prosemirror-commands'
import { NodeType } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { splitListItem, toggleList } from "prosemirror-schema-list";
import { wrapInList, liftListItem, sinkListItem } from "prosemirror-schema-list";
import { CdkObserveContent } from '@angular/cdk/observers';
import { keymap } from 'prosemirror-keymap';
import { schema } from 'prosemirror-schema-basic';
import { TextSelection } from 'prosemirror-state';

export function toggleList(listType: NodeType) {
    return (state: EditorState, dispatch: any) => {

        const { schema } = state;
        const { bullet_list, ordered_list, list_item } = schema.nodes;

        const isListActive = state.selection.$from
            .sharedDepth(state.selection.to) >= 2;

        if (isListActive) {
            const result = liftListItem(list_item)(state, dispatch);
            return result;
        } else {
            const result = wrapIn(listType)(state, dispatch);
            return result;
        }
    };
}


export function toggleBlockquote(blockquoteType: NodeType) {
    return (state: EditorState, dispatch: any) => {
        const { selection } = state;
        const { $from, $to } = selection;
        const blockquoteNode = findParentNodeOfType(blockquoteType)(selection);

        if (blockquoteNode) {
            if (dispatch) {
                dispatch(state.tr.lift($from.blockRange($to), 0));
            }
            return true;
        } else {
            // Otherwise, wrap the selection in the blockquote
            const result = wrapIn(blockquoteType)(state, dispatch);
            return result;
        }
    };
}

function findParentNodeOfType(nodeType: NodeType) {
    return (selection: any) => {
        const { $from } = selection;
        for (let d = $from.depth; d > 0; d--) {
            const node = $from.node(d);
            if (node.type === nodeType) {
                return node;
            }
        }
        return null;
    };
}

export const listKeymap = keymap({
    Enter: (state, dispatch, view) => {
        const { $from } = state.selection
        for (let d = $from.depth; d > 0; d--) {
            const node = $from.node(d)
            if (node.type.name === 'list_item') {
                return splitListItem(state.schema.nodes['list_item'])(state, dispatch)
            }
        }
        return false
    }
})


export const addPageBreak = (state: EditorState, dispatch: any) => {

    const { tr } = state;
    const pageBreak = state.schema.nodes['hard_break'].create();
    const { selection } = state;
    const { $from, $to } = selection;

    tr.insert($from.pos, pageBreak);
    tr.setSelection(TextSelection.near(tr.doc.resolve($from.pos + 1)));
    dispatch(tr);
    return true;
}

export const appltHeadingStyle = (state: EditorState, dispatch: any, nodeType: NodeType) => {
    const { selection } = state;
    const { $from, $to } = selection;
    let isActive = false;
    let level = 1;
    const node = $from.node()
    if (node.type === nodeType) {
        isActive = true;
        level = node.attrs.level || 1;
    }
    return level;
}

export const validateEmail = (email: string) => {
    return email.match(/^[a-z0-9._%+-]+@[a-z0-9._%+-]+\.[a-z]{2,4}/);
}

//#region *SLASH MENU
export const SLASH_MENU_KEYS = {
    AI_ASSISTANT: 'ai-assistant',
    QUOTE: 'quote',
    BULLET_LIST: 'bullet-list',
    HEADING_1: 'heading-1',
    HEADING_2: 'heading-2',
    HEADING_3: 'heading-3',
    CODE_BLOCK: 'code-block',
    TABLE: 'table',
    ORDERED_LIST: 'ordered-list',
    FILE_MANAGER: 'file-manager',
    PARAGRAPH: 'paragraph',
}

export const SLASH_MENU_ICONS = {
    AI_ASSISTANT: 'smart_toy',
    QUOTE: 'format_quote',
    BULLET_LIST: 'format_list_bulleted',
    HEADING_1: 'format_h1',
    HEADING_2: 'format_h2',
    HEADING_3: 'format_h3',
    CODE_BLOCK: 'code',
    TABLE: 'table_chart',
    ORDERED_LIST: 'format_list_numbered',
    FILE_MANAGER: 'file_present',
    PARAGRAPH: 'view_headline',
}

// interface for menu items
export interface SlashMenuItem {
    key: string;
    label: string;
    icon: string;
    action?: Function;
    customClass?: string;
    customClassIcon?: string;
    customClassLabel?: string;
}

export const SLASH_MENU_ITEMS: { [key: string]: SlashMenuItem } = {
    [SLASH_MENU_KEYS.AI_ASSISTANT]: {
        key: SLASH_MENU_KEYS.AI_ASSISTANT,
        label: 'AI Assistant',
        icon: SLASH_MENU_ICONS.AI_ASSISTANT,
    },
    [SLASH_MENU_KEYS.QUOTE]: {
        key: SLASH_MENU_KEYS.QUOTE,
        label: 'Quote',
        icon: SLASH_MENU_ICONS.QUOTE,
    },
    [SLASH_MENU_KEYS.BULLET_LIST]: {
        key: SLASH_MENU_KEYS.BULLET_LIST,
        label: 'Bullet List',
        icon: SLASH_MENU_ICONS.BULLET_LIST,
    },
    [SLASH_MENU_KEYS.HEADING_1]: {
        key: SLASH_MENU_KEYS.HEADING_1,
        label: 'Heading 1',
        // icon: SLASH_MENU_ICONS.HEADING_1,
    },
    [SLASH_MENU_KEYS.HEADING_2]: {
        key: SLASH_MENU_KEYS.HEADING_2,
        label: 'Heading 2',
        // icon: SLASH_MENU_ICONS.HEADING_2,
    },
    [SLASH_MENU_KEYS.HEADING_3]: {
        key: SLASH_MENU_KEYS.HEADING_3,
        label: 'Heading 3',
        // icon: SLASH_MENU_ICONS.HEADING_3,
    },
    [SLASH_MENU_KEYS.CODE_BLOCK]: {
        key: SLASH_MENU_KEYS.CODE_BLOCK,
        label: 'Code Block',
        icon: SLASH_MENU_ICONS.CODE_BLOCK,
    },
    [SLASH_MENU_KEYS.TABLE]: {
        key: SLASH_MENU_KEYS.TABLE,
        label: 'Table',
        icon: SLASH_MENU_ICONS.TABLE,
    },
    [SLASH_MENU_KEYS.ORDERED_LIST]: {
        key: SLASH_MENU_KEYS.ORDERED_LIST,
        label: 'Ordered List',
        icon: SLASH_MENU_ICONS.ORDERED_LIST,
    },
    [SLASH_MENU_KEYS.FILE_MANAGER]: {
        key: SLASH_MENU_KEYS.FILE_MANAGER,
        label: 'File Manager',
        icon: SLASH_MENU_ICONS.FILE_MANAGER,
    },
    [SLASH_MENU_KEYS.PARAGRAPH]: {
        key: SLASH_MENU_KEYS.PARAGRAPH,
        label: 'Paragraph',
        icon: SLASH_MENU_ICONS.PARAGRAPH,
    }
};
//#endregion

interface Token {
    tag: string;
    info?: string;
    attrGet(attr: string): string | null;
}

// Define rules to map Markdown tokens to ProseMirror nodes
export const customTokens = {
    // Define paragraph handling
    paragraph: { block: "paragraph" },

    // Blockquote
    blockquote: { block: "blockquote" },

    hardbreak: { node: "hard_break" },

    // Horizontal rule
    horizontal_rule: { node: "horizontal_rule" },
    hr: { node: "horizontal_rule" },

    // Headings (h1-h6)
    heading: {
        block: "heading",
        getAttrs: (token: Token) => ({ level: +token.tag.slice(1), align: 'left' })
    },

    // Code block
    code_block: {
        block: "code_block",
        getAttrs: (token: Token) => ({ language: token.info || null })
    },

    // Inline code
    code_inline: { mark: "code" },

    // Strong (bold)
    strong: { mark: "strong" },

    // Emphasis (italic)
    em: { mark: "em" },

    // Underline
    underline: { mark: "underline" },

    // Strikethrough
    strikethrough: { mark: "strikethrough" },

    // Ordered list
    ordered_list: { block: "ordered_list" },

    // Bullet list
    bullet_list: { block: "bullet_list" },

    // List item
    list_item: { block: "list_item" },

    // Images
    image: {
        node: "image",
        getAttrs: (token: Token) => ({
            src: token.attrGet("src"),
            alt: token.attrGet("alt") || null,
            title: token.attrGet("title") || null
        })
    },

    // Link
    link: {
        mark: "link",
        getAttrs: (token: Token) => ({
            href: token.attrGet("href"),
            title: token.attrGet("title") || null
        })
    },

    // Custom comment mark
    comment: {
        mark: "comment",
        getAttrs: (token: Token) => ({
            id: token.attrGet("data-comment-id"),
            color: token.attrGet("data-comment-color") || ''
        })
    },

    // Tables (using ProseMirror table nodes)
    table: { block: "table" },
    table_row: { block: "table_row" },
    table_cell: { block: "table_cell" },
    table_header: { block: "table_header" },
};