export enum USER_ROLE {
    ADMIN = 'ADMIN',
    MEMBER = 'MEMBER'
}
export enum USER_TYPE {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL'
}

export enum CONTRACT_CHECKLIST_STATUS {
    COMPLIANCE = "COMPLIANCE",
    NOT_IN_COMPLIANCE = "NOT_IN_COMPLICANCE",
    SAVE_FOR_LATER = "SAVE_FOR_LATER"
}